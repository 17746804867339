$(function() {
  // State
  let selectedYear = 2022
  const selectedLanguage = window.location.pathname.split('/')[1]
  
  // Elements
  const releasesEls = document.querySelectorAll('[data-js="releases"] article')
  const yearTabEl = document.querySelectorAll('[data-js="tab"]')
  const yearTabEls = document.querySelectorAll('[data-js="tab"] [data-year]')
  const triggerEl = document.querySelector('[data-js="trigger"]')
  const selectedYearEl = document.querySelector('[data-selectedYear]')

  triggerEl.checked = false

  // methods
  function showReleases(selectedYear) {
    releasesEls.forEach(release => {
      const releaseYear = parseInt(release.dataset.year)

      if (releaseYear === selectedYear) {
        release.style.display = 'inline-flex'
      } else {
        release.style.display = 'none'
      }
    })
  }

  function setYear(year) {
    if (!yearTabEl[0]) return null

    selectedYear = year
    selectedYearEl.setAttribute('data-selectedYear', year)

    const yearTab = document.querySelector(`[data-year='${year}']`)
    yearTab.classList.add('tab--active')
    showReleases(year)
  }

  const DEFAULT_SELECTED_YEAR = 2023

  // actions
  setYear(DEFAULT_SELECTED_YEAR)
  
  yearTabEls.forEach((tab) => {
    tab.addEventListener('click', (e) => {
      e.stopPropagation()

      yearTabEls.forEach(t => {
        t.classList.remove('tab--active')
      })

      const tabYear = parseInt(tab.dataset.year)
      setYear(tabYear)
    })
  })

  // languageSwitcher

  const languageBtns = document.querySelectorAll('[data-js="languageSwitcher"] [data-lang]')
  
  const translation = {
    'ochrana-soukromi': 'privacy-policy',
    'encyclopedie': 'encyclopedia',
  }

  function replaceLng(url, [replacedName, replacingName]) {
    return url.includes(replacedName) ? url.replace(replacedName, replacingName) : url
  }

  languageBtns.forEach(el => {
    const btnLang = el.dataset.lang
    let translatedVariantUrl = window.location.pathname.split('/').map((s, i) => i === 1 ? btnLang : s).join('/')

    const translationMap = Object.entries(translation)

    if (btnLang === 'en') {
      translationMap.map(([csName, enName]) => {
        translatedVariantUrl = replaceLng(translatedVariantUrl, [csName, enName])  
      })
    }

    if (btnLang === 'cs') {
      translationMap.map(([csName, enName]) => {
        translatedVariantUrl = replaceLng(translatedVariantUrl, [enName, csName])
      })
    }
    
    el.href = translatedVariantUrl

    if (btnLang === selectedLanguage) {
      el.classList.add('text-accent-100')
    }
  })
  
  // Release dropdown
  
  const dropdownEl = document.querySelector('[data-js="dropdown"]')
  const dropdownTriggerEl = document.querySelector('[data-js="dropdown-trigger"]')

  if (dropdownEl && dropdownTriggerEl) {
    dropdownTriggerEl.addEventListener('mouseenter', (e) => {
      e.preventDefault()
      dropdownEl.style.opacity = 1
      dropdownEl.style.visibility = 'visible'
      dropdownEl.style.transform = 'translate3d(0,0,0)'
    })
    
    dropdownTriggerEl.addEventListener('mouseleave', (e) => {
      e.preventDefault()
      dropdownEl.style.opacity = 0
      dropdownEl.style.visibility = 'hidden'
      dropdownEl.style.transform = 'translate3d(0,-1rem,0)'
    })
  }


  // ScrollTo

  $("a[href^='/cs/#'], a[href^='/en/#'], a[href^='/cs#'], a[href^='/en#'], a[href^='#']").on("click", function(e) {
    if (!(window.location.pathname === '/cs/' || window.location.pathname === '/en/')) return

    e.preventDefault()
    const targetEl = $(this).attr("href").split('#')[1]

    $("html, body").animate({
      scrollTop: $('#'+targetEl).offset().top
    }, 1000);
    triggerEl.checked = false
    window.location.href.split('#')[0]
    return false;
  });
  
  if ($(window.location.hash).length > 1) {
    $("html, body").animate({
      scrollTop: $(window.location.hash).offset().top
    }, 1000);
    window.location.href.split('#')[0]
  }

  // Cookie consent

  const cookieBarEl = document.querySelector('[data-js="cookie-bar"]')
  const isCookieConsent = JSON.parse(localStorage.getItem('cookie-consent'))

  if (isCookieConsent === null) {
    setTimeout(() => {
      cookieBarEl.classList.add('cb-show')
    }, 600)
  }

  const acceptEl = document.querySelector('[data-js="accept"]')
  const rejectEl = document.querySelector('[data-js="reject"]')

  acceptEl.addEventListener('click', (e) => {
    e.preventDefault()
    localStorage.setItem('cookie-consent', true)
    cookieBarEl.classList.remove('cb-show')
  })
  
  rejectEl.addEventListener('click', (e) => {
    e.preventDefault()
    localStorage.setItem('cookie-consent', false)
    cookieBarEl.classList.remove('cb-show')
  })

  // Validator

  $("#contactForm").validate({
    ignore: ".ignore",
    rules: {
      name: "required",
      email: {
        required: true,
        email: true
      },
      company: "required",
      message: "required",
      hiddenRecaptcha: {
        required: function () {
          return grecaptcha.getResponse() == ''
        }
    }

    },
    submitHandler: function(form) {
      grecaptcha.execute();
    }
  });

  if (window.location.search === '?success') {
    const $submitBtn = $('[data-js="submit"]')
    const $successText = $('[data-js="success"]')

    $submitBtn.hide()
    $successText.show()
  }
});